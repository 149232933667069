import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'
import Work from '../../components/work/Work';
import "./home.css";
import Footer from '../../components/footer/Footer';

const Home = () => {
  return (
    <div>
        <Navbar/>
        <Header/>
        <Work/>
        <Footer/>
    </div>

  )
}

export default Home
import React from 'react'
import "./footer.css"
import "../../styles/styles.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDribbble, faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerContainer">
                <div className="footerTxt">
                    Connect with me
                </div>
                <div className="footerSubTxt">
                    <p>Feel free to have a chat for collaboration or just say hello!</p> <span><img src="assets/wave-icon.png" alt="" /></span>
                </div>
                <div className="footerSocial">
                    <a href="https://www.linkedin.com/in/qnamtran/" target='_blank' rel="noreferrer"><span className='socialIcon'><FontAwesomeIcon icon={faLinkedinIn} /></span></a>
                    <a href="https://dribbble.com/namtranq" target='_blank' rel="noreferrer"><span className='socialIcon'><FontAwesomeIcon icon={faDribbble} /></span></a>
                    <a href="https://github.com/qnamtran" target='_blank'rel="noreferrer"><span className='socialIcon'><FontAwesomeIcon icon={faGithub} /></span></a>
                </div>
            </div>
        </div>
    )
}

export default Footer
import "./navbar.css"
import "../../styles/styles.css"
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../../assets/nt-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faLocationDot, faMobileScreen } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navContainer">
        <div className="navWrapper">
          <Link to='/' onClick={() => {
            window.scroll(0, 0);
          }}><div className="logo"><Logo /></div></Link>
          {/* <div className="logo"><a href="/"><Logo /></a></div> */}
          {/* <div className="navItems">
                <div className="navItem">Home</div>
                <div className="navItem">About</div>
                <div className="navItem">Work</div>
            </div> */}
          <Popup trigger=
                {<button className='secondary-btn hover-effect-btn'>Get in touch</button>} 
                modal nested>
                {
                    close => (
                        <div className='modal'>
                            <div>
                                <span className='close-btn' onClick=
                                    {() => close()}>
                                        <FontAwesomeIcon icon={faCircleXmark}/>
                                </span>
                            </div>
                            <div className='content'>
                                <div className="contactInfoPopup">
                                    <h4>Get in touch</h4>
                                    <div className="contactInfoItem">
                                        <span><FontAwesomeIcon icon={faLocationDot}/></span>
                                        <p>Thunder Bay, Ontario, Canada</p>
                                    </div>
                                    <div className="contactInfoItem">
                                        <span><FontAwesomeIcon icon={faMobileScreen}/></span>
                                        <p>+1 807-709-8998</p>
                                    </div>
                                    <div className="contactInfoItem">
                                        <span><FontAwesomeIcon icon={faAt}/></span>
                                        <p>qnam.can@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Popup>
          {/* <div className="ctaItems">
            <button className="secondary-btn">Contact</button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Navbar
import{
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import Home from "./pages/home/Home";
import FastDrop from "./pages/caseStudyFastDrop/FastDrop";
import Faster from "./pages/caseStudyFaster/Faster";
import Summit from "./pages/caseStudySummit/Summit";
import Adiona from "./pages/caseStudyAdiona/Adiona";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/fastdrop" element={<FastDrop/>}/>
        <Route path="/faster" element={<Faster/>}/>
        <Route path="/summit" element={<Summit/>}/>
        <Route path="/adiona" element={<Adiona/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;

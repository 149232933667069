import React from 'react'
import "./header.css"
import "../../styles/styles.css"

const Header = () => {
  return (
    <div className="header">
      <div className="headerContainer">
        <div className="headerIntroduction">
          {/* <div className="headerHelloTxt">
            <p>Hi, I'm Quang Nam Tran</p>
          </div> */}
          <div className="headerIntroTxt">
            <h2>Hi! I’m <span className='myname'>Quang Nam Tran</span>, a product designer with 9 years' experience and proven ability to bring projects from concept to completion.</h2>
          </div>
          <div className="headerIntroSubTxt">
            <p>Just finished my second diploma degree in Computer Programming, currently open for new opportunity in <span>Product Design</span> and <span>Data Analytic</span>.</p>
          </div>
        </div>
        {/* <div className="headerImage">
          <img src="/assets/hero-img.png" alt="" />
        </div> */}
      </div>
    </div>
  )
}

export default Header
import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import "./fastdrop.css"
import "../../styles/casestudy.css"
import "../../styles/styles.css"

const FastDrop = () => {
    return (
        <div>
            <Navbar />
            <div className="caseStudyDetail">
                <div className="caseStudyDetailContainer">
                    <div className="caseStudyDetailBackground bg-green">
                        <div className="caseStudyDetailCoverContainer">
                            <div className="caseStudyDetailTitleContainer">
                                <div className="caseStudyDetailTitle txt-white">
                                    <div className='company-logo'>
                                        <img src="assets/company-logos/fastdrop-logo.png" alt="" />
                                    </div>
                                    <h3>Recycling and Waste Management</h3>
                                    <div className="caseStudyDetailHeading"><p>FastDrop enhances efficiency and user experience in recycling through automated solutions</p></div>
                                </div>
                            </div>
                            <div className="caseStudyDetailCoverImage">
                                <img src="/assets/case-study-covers/fastdrop-cover.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailIntroContainer">
                            <div className="projectIntro">
                                <h4>About FastDrop</h4>
                                <p>Brook Lotzkar, originally a film and digital media producer, ventured into the recycling business by chance. “I’ve always been passionate about the environment and recycling is an important part in reducing the amount of waste we send to landfills,” he says. That’s not surprising as Brook’s family had operated one of Vancouver’s first recycling centers back in the 60s. It gave him an opportunity to use his film studies training to encourage more people to recycle.</p>
                                <p>Currently, Brook serves as the IT and marketing manager at Regional Recycling, a Vancouver-based company that operates a network of recycling depots across Western Canada. These depots allow customers to return bottles and cans for a refund, which manufacturers can then reuse.</p>
                            </div>
                            <div className="introDivider">

                            </div>
                            <div className="projectOverview">
                                <h4>Overview</h4>
                                <div className="projectOverviewDetail">
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Industry</p>
                                        </div>
                                        <div className="textData">
                                            <p>Waste Management</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Platform</p>
                                        </div>
                                        <div className="textData">
                                            <p>Web</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Timeline</p>
                                        </div>
                                        <div className="textData">
                                            <p>Jun 2020 - Mar 2021</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>My Role</p>
                                        </div>
                                        <div className="textData">
                                            <p>UX Design, UI Design</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>The Need for Automation in Recycling</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>The onset of COVID-19 prompted a shift in customer behavior, with many seeking to minimize their time spent in recycling depots. As a user experience designer, I was brought in to develop FastDrop, a contactless recycling solution designed to meet this new demand. “If we didn’t do this, people would recycle less because they wouldn’t want to risk their health and safety for a few extra dollars.” Brook explains.</p>
                                    <p>Previously, the recycling process at Regional Recycling was labor-intensive. Employees manually counted bottles and cans, issued receipts, and customers withdrew their earnings from an ATM. FastDrop streamlined these processes, reducing manual labor and providing Brook with valuable data on recycling volumes.</p>
                                    <img  className='rounded-img' src="assets/case-study-images/fastdrop/fastdrop-img1.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>How FastDrop Works</h4>
                                <p>In my role as a user experience designer, I focused on creating a seamless and intuitive experience for FastDrop users, automating the recycling process while ensuring ease of use. FastDrop Recycling and Waste Management contains 4 main components.</p>
                                <div className="contentDivider"></div>
                                <div 
                                className="caseStudyDetailInnerContentWrapper">
                                    <h5>1. Customer Portal</h5>
                                    <p>A comprehensive online platform where users can manage their recycling activities. They can create drop-offs, print labels, view transaction history, and track earnings. Users can opt to have their earnings deposited directly into their bank accounts or donated to charity.</p>
                                    <img src="assets/case-study-images/fastdrop/fastdrop-customer-portal.png" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>2. In-Depot Registration</h5>
                                    <p>Customers register for a FastDrop account using a tablet at a Regional Recycling depot. They specify the number of bags they are dropping off and print a FastDrop label with a unique ID, which they attach to their bags.</p>
                                    <img src="assets/case-study-images/fastdrop/fastdrop-indepot.png" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>3. Automated Sorting and Credit</h5>
                                    <p>Staff transport these bags to a machine that counts the recyclables and calculates earnings, which are then credited to the customer’s FastDrop account. Upon reaching a $20 threshold, customers can request an electronic transfer to their bank account.</p>
                                    <img src="assets/case-study-images/fastdrop/fastdrop-account.png" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>4. Bottle Drives</h5>
                                    <p>A unique feature that allows customers to donate their earnings to a charity of their choice, gamifying the recycling process and fostering community involvement.</p>
                                    <img className='rounded-img' src="assets/case-study-images/fastdrop/fastdrop-bottle-drives.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>The Development Journey</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>The complexity of integrating various workflows, such as item counting and label printing, made the development of FastDrop a significant undertaking, spanning eight months.</p>
                                    <p>Post-development, it took two weeks to test and implement the system across eight depots in Western Canada.</p>
                                </div>
                            </div>
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Future Plans for FastDrop</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>Brook envisions expanding the system with a home pickup service, DepoZip. This addition will allow customers to request home pickups, integrating seamlessly with existing commercial recycling operations. Plans include further automation, such as optical character recognition for faster calculations, automated barcode scanning, and more diverse payment options.</p>
                                    <p>With technological advancements like FastDrop, recycling is becoming more efficient and rewarding, encouraging more people to participate in sustainable practices.</p>
                                    <img className='rounded-img' src="assets/case-study-images/fastdrop/fastdrop-depozip.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FastDrop
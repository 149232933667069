import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import "./adiona.css"
import "../../styles/casestudy.css"
import "../../styles/styles.css"

const Adiona = () => {
    return (
        <div>
            <Navbar />
            <div className="caseStudyDetail">
                <div className="caseStudyDetailContainer">
                    <div className="caseStudyDetailBackground bg-darkblue">
                        <div className="caseStudyDetailCoverContainer">
                            <div className="caseStudyDetailTitleContainer">
                                <div className="caseStudyDetailTitle txt-white">
                                    <div className='company-logo'>
                                        <img src="assets/company-logos/adiona-logo.png" alt="" />
                                    </div>
                                    <h3>Dynamic Route Planning</h3>
                                    <div className="caseStudyDetailHeading"><p>Streamline all your deliveries in one app and connect your drivers in real-time</p></div>
                                </div>
                            </div>
                            <div className="caseStudyDetailCoverImage">
                                <img src="/assets/case-study-covers/adiona-detail-cover.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailIntroContainer">
                            <div className="projectIntro">
                                <h4>About Adiona</h4>
                                <p>Adiona is a cloud-based software platform designed to optimize logistics and supply chain operations. It aids large delivery fleets in planning routes efficiently, resulting in cost savings and reduced greenhouse gas emissions.</p>
                                <p>The platform can optimize thousands of delivery stops in seconds and includes advanced simulation and modeling tools for assessing the integration of electric vehicles or cargo bikes into fleets.</p>
                            </div>
                            <div className="introDivider">

                            </div>
                            <div className="projectOverview">
                                <h4>Overview</h4>
                                <div className="projectOverviewDetail">
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Industry</p>
                                        </div>
                                        <div className="textData">
                                            <p>Software</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Platform</p>
                                        </div>
                                        <div className="textData">
                                            <p>Mobile App</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Timeline</p>
                                        </div>
                                        <div className="textData">
                                            <p>Jul 2022 - Sep 2022</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>My Role</p>
                                        </div>
                                        <div className="textData">
                                            <p>User Research, UX Design, UI Design</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Business Challenge</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>Despite Adiona's robust and user-friendly platform, route planners faced difficulties in transmitting optimized routes to drivers. This often required customers to rely on printed routes or third-party apps to ensure drivers followed the assigned routes efficiently.</p>
                                    <img className='rounded-img' src="assets/case-study-images/adiona/adiona-img1.png" alt="" />
                                    <p>Recognizing the need to enhance the platform’s value and accessibility, Adiona’s CEO initiated the development of a driver app. This app aimed to enable drivers to easily view routes, track progress in real-time, and ensure secure and timely deliveries through photo and signature capture features.</p>
                                    <p>The objective was to launch a minimum viable product (MVP) within three months, using React Native for cross-platform deployment on both Android and iOS. Adiona’s engineering team focused on backend APIs, design, and workflow, while the frontend user interface of the driver app was developed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Implementing a seamless and secure log-in experience for driver</h4>
                                <p>The initial technical challenge was to create a secure and seamless login process for drivers. Collaborating with Adiona’s product and engineering teams, the solution involved the following steps:</p>
                                <div
                                    className="caseStudyDetailInnerContentWrapper">
                                    <div className="stepContainer">
                                        <div className="stepItem stepItem3 bg-white border-lightgrey">
                                            <div className="stepNumber bg-lightblue txt-blue">
                                                <span>1</span>
                                            </div>
                                            <div className="stepContent">
                                                <p>Drivers are added to the Adiona dashboard using their email addresses.</p>
                                            </div>
                                        </div>
                                        <div className="stepItem stepItem3 bg-white border-lightgrey">
                                            <div className="stepNumber bg-lightblue txt-blue">
                                                <span>2</span>
                                            </div>
                                            <div className="stepContent">
                                                <p>On first-time app access, drivers enter their email addresses.</p>
                                            </div>
                                        </div>
                                        <div className="stepItem stepItem3 bg-white border-lightgrey">
                                            <div className="stepNumber bg-lightblue txt-blue">
                                                <span>3</span>
                                            </div>
                                            <div className="stepContent">
                                                <p>An email with a magic link is sent to the driver.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p>This link contains an invitation code that is exchanged by the app for a secure token, which automatically logs the driver in and allows them to view their routes.</p>
                                    <img className='rounded-img grey-bg-img' src="assets/case-study-images/adiona/adiona-login.png" alt="" />
                                </div>
                            </div>
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Providing key delivery information and customer notifications</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>After resolving these technical challenges, the focus shifted to enhancing the driver app to align with other routing and delivery management solutions. Features were added to ensure drivers had access to all necessary delivery information and could send text notifications to customers before arrival. These notifications included a real-time tracking link, allowing customers to track the driver’s location and prepare for delivery.</p>
                                    <img className='rounded-img grey-bg-img' src="assets/case-study-images/adiona/adiona-customer.png" alt="" />
                                    <p>Customers could sign for deliveries directly on the driver app, and in cases where they were unavailable, drivers could leave the package and take a photo as proof of delivery, which was immediately uploaded to the Adiona platform.</p>
                                    <img className='rounded-img grey-bg-img' src="assets/case-study-images/adiona/adiona-complete.png" alt="" />
                                </div>
                            </div>
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Presenting optimized routes to drivers</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>Adiona needed to gather accurate location data to improve its route optimization algorithms, which traditionally prioritize minimizing total distance over driver-preferred routes. These preferences consider local traffic conditions, road closures, and parking restrictions.</p>
                                    <img className='rounded-img' src="assets/case-study-images/adiona/adiona-diagram.png" alt="" />
                                    <p>To address this, foreground in-app location tracking was implemented, sending the driver's real-time GPS position to Adiona’s servers every 30 seconds. If internet connectivity was lost, location data would be stored locally and uploaded once connectivity was restored. The app also verified the driver's location upon arrival, enhancing delivery address accuracy and minimizing errors.</p>
                                    <p>Adiona needed to gather accurate location data to improve its route optimization algorithms, which traditionally prioritize minimizing total distance over driver-preferred routes. These preferences consider local traffic conditions, road closures, and parking restrictions.</p>
                                    <p>To address this, foreground in-app location tracking was implemented, sending the driver's real-time GPS position to Adiona’s servers every 30 seconds. If internet connectivity was lost, location data would be stored locally and uploaded once connectivity was restored. The app also verified the driver's location upon arrival, enhancing delivery address accuracy and minimizing errors.</p>
                                    <img className='rounded-img grey-bg-img' src="assets/case-study-images/adiona/adiona-confirm.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Conclusion</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>The new driver app is expected to revolutionize Adiona's service by enabling innovation, cost savings, and improved experiences for drivers and customers. It simplifies following optimized routes, thereby reducing the environmental impact of transport and logistics.</p>
                                    <img className='rounded-img' src="assets/case-study-images/adiona/adiona-img2.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Adiona
import React from 'react'
import { Link } from "react-router-dom";
import "./work.css"
import "../../styles/styles.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faHourglass } from '@fortawesome/free-solid-svg-icons'

function Work() {
    return (
        <div className="work">
            <div className="workContainer">
                <div className="workIntroduction">
                    <div className="workIntroTxt">
                        Case Studies
                    </div>
                    <div className="workIntroSubTxt">
                        <h2>Selected Works</h2>
                    </div>
                </div>
                <div className="caseStudyListContainer">
                    <div className="caseStudyList">
                        <div className="caseStudyItem bg-white">
                            <div className="caseStudyTitle">
                                <h3>Fusion Analytics for public Transport Emergency Response (FASTER)</h3>
                            </div>
                            <div className="caseStudyCover">
                                <img src="/assets/case-study-covers/faster-cover.png" alt="" />
                            </div>
                            <Link to='/faster' onClick={() => {
                                window.scroll(0, 0);
                            }}><button className='nobg-btn animated-btn hover-effect-nobg-btn'>View Case Study <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span></button></Link>
                        </div>
                        <div className="caseStudyItem bg-black">
                            <div className="caseStudyTitle txt-white">
                                <h3>Singapore Urban Multi-Modal Integrated Transport Simulation (SUMMIT)</h3>
                            </div>
                            <div className="caseStudyCover">
                                <img src="/assets/case-study-covers/summit-cover.png" alt="" />
                            </div>
                            <Link to='/summit' onClick={() => {
                                window.scroll(0, 0);
                            }}><button className='nobg-btn animated-btn txt-white hover-effect-nobg-btn'>View Case Study <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span></button></Link>
                        </div>
                    </div>
                    <div className="caseStudyListFull">
                        <div className="caseStudyItemFull bg-green">
                            <div className="caseStudyTitleContainer">
                                <div className="caseStudyTitle txt-white">
                                    <div className='company-logo'>
                                        <img src="assets/company-logos/fastdrop-logo.png" alt="" />
                                    </div>
                                    <h3>Recycling and Waste Management</h3>
                                    <div className="caseStudyHeading"><p>FastDrop enhances efficiency and user experience in recycling through automated solutions</p></div>
                                </div>
                                <Link to='/fastdrop' onClick={() => {
                                    window.scroll(0, 0);
                                }}><button className='nobg-btn animated-btn txt-white hover-effect-nobg-btn'>View Case Study <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span></button></Link>
                            </div>
                            <div className="caseStudyCoverFull">
                                <img src="/assets/case-study-covers/fastdrop-cover.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyList">
                        <div className="caseStudyItem bg-pink">
                            <div className='company-logo'>
                                <img src="assets/company-logos/rb-logo.png" alt="" />
                            </div>
                            <div className="caseStudyTitle txt-white">
                                <h3>Sales Territory Planning</h3>
                            </div>
                            <div className="caseStudyCover">
                                <img src="/assets/case-study-covers/rb-cover.png" alt="" />
                            </div>
                            <button className='nobg-btn animated-btn txt-white hover-effect-nobg-btn disabled-btn'>Case Study Coming Soon <span className='icon'><FontAwesomeIcon icon={faHourglass} /></span></button>
                        </div>
                        <div className="caseStudyItem bg-darkblue summit-astar">
                            <div className='company-logo'>
                                <img src="assets/company-logos/adiona-logo.png" alt="" />
                            </div>
                            <div className="caseStudyTitle txt-white">
                                <h3>Dynamic Route Planning</h3>
                            </div>
                            <div className="caseStudyCover">
                                <img src="/assets/case-study-covers/adiona-cover.png" alt="" />
                            </div>
                            <Link to='/adiona' onClick={() => {
                                window.scroll(0, 0);
                            }}><button className='nobg-btn animated-btn txt-white hover-effect-nobg-btn'>View Case Study <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span></button></Link>
                        </div>
                    </div>
                    <div className="caseStudyListFull">
                        <div className="caseStudyItemFull bg-white">
                            <div className="caseStudyTitleContainer">
                                <div className="caseStudyTitle">
                                    <div className='company-logo'>
                                        <img src="assets/company-logos/saathi-logo.png" alt="" />
                                    </div>
                                    <h3>Get rewarded when you save for your goals</h3>
                                    <div className="caseStudyHeading"><p>Saathi offers high-interest digital savings account and awesome rewards so you can save for the things you love</p></div>
                                </div>
                                <button className='nobg-btn animated-btn txt-black hover-effect-nobg-btn disabled-btn'>Case Study Coming Soon <span className='icon'><FontAwesomeIcon icon={faHourglass} /></span></button>
                            </div>
                            <div className="caseStudyCoverFull">
                                <img src="/assets/case-study-covers/saathi-cover.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyListFull">
                        <div className="caseStudyItemFull bg-dark">
                            <div className="caseStudyTitleContainer">
                                <div className="caseStudyTitle txt-white">
                                    <div className='company-logo'>
                                        <img src="assets/company-logos/ohn-logo.png" alt="" />
                                    </div>
                                    <h3>Global Financial Network</h3>
                                    <div className="caseStudyHeading"><p>OneHypernet connects the world’s foreign exchange markets – banks, corporates, and payment service providers on a unified ecosystem powered by enterprise blockchain technology.</p></div>
                                </div>
                                <button className='nobg-btn animated-btn txt-white hover-effect-nobg-btn disabled-btn'>Case Study Coming Soon <span className='icon'><FontAwesomeIcon icon={faHourglass} /></span></button>
                            </div>
                            <div className="caseStudyCoverFull">
                                <img src="/assets/case-study-covers/ohn-cover.png" alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Work